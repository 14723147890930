.top-container{
    background-color: #EAE8E8;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  border-top:1px solid #EAE8E8; border-bottom: 1px solid #EAE8E8;
}
.deliveryToContainer{
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
   border-bottom: 1px solid rgb(110, 110, 110);
}
.easy-text{
    font-size: 14px;
    font-weight: bold;
    color: black;
    padding: 0px;
    margin: 0px;
}
@media only screen and (max-width: 768px) {

}