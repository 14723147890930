.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: white;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color:rgba(102, 102, 102, 0.5);
  font-size: 18px;
  font-weight: 400;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:black ;
  background-color:transparent;
  pointer-events: painted;  
}
.btn-search:focus ~ .input-search{
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
li {
    list-style: none;
}

a {
    text-decoration: none;
}

.header{
    border-bottom: 1px solid #eeecec;
    background-color: white;
      overflow: hidden;


}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 20px;
          overflow: hidden;

}
.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}
.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;

}

.nav-item {
    margin-left: 5rem;
}

.nav-link{
    font-size: 1.6rem;
    font-weight: 400;
    color: #475569;
}

.nav-link:hover{
    color: #0088CC;
}
.ll{
                      display: grid;

}
.nav-logo {
   color: black;
   font-weight: bold;
   font-size: 28px;
}
.subNavCont{
    height: 80px;
    margin-top: 68px;
}
@media only screen and (max-width: 768px) {
    .subNavCont{
    height: 50px;
    margin-top: 42px;
}
.ll{
                      display: block;

}
.ant-menu-item{
    height: 80px;
    border-bottom-width: 1px;
    border-bottom-color: black;
}
      .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
    .hamburger-menu-button {
  width: 40px;
  height: 40px;
  padding: 6px;
  display: block;
  position: relative;
  margin-top: 35px;
  z-index: 100;
  background: #2980b9;
  border: 5px solid white;
  box-sizing: content-box;
  border-radius: 50%;
  text-indent: 100%;
  color: transparent;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
}
.hamburger-menu-button-open {
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -12px;
}
.hamburger-menu-button-open, 
.hamburger-menu-button-open::before, 
.hamburger-menu-button-open::after {
  position: absolute;
  width: 24px;
  height: 2px;
  background: #fff;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.hamburger-menu-button-open::before, 
.hamburger-menu-button-open::after {
  left: 0;
  content: "";
}
.hamburger-menu-button-open::before {
  top: 6px;
}
.hamburger-menu-button-open::after {
  bottom: 6px;
}
.hamburger-menu-button-close {
  background: transparent;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.hamburger-menu-button-close::before {
  -webkit-transform: translateY(-6px) rotate(45deg);
          transform: translateY(-6px) rotate(45deg);
}
.hamburger-menu-button-close::after {
  -webkit-transform: translateY(6px) rotate(-45deg);
          transform: translateY(6px) rotate(-45deg);
}
.ham-menu {
  position: absolute;
  top: 42px;
  left: 48px;
  margin: auto;
  max-width: 570px;
  overflow: hidden;
}
    .nav-menu {
        padding: 20px;
        position: fixed;
        left: -100%;
        top: 2rem;
        flex-direction: column;
        justify-content:start;
        background-color: #fff;
        width: 100%;
        border-radius: 10px;
        height: 100%;
        text-align: left;
        align-items: flex-start;
        transition: 0.3s;
        z-index: 100;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 2.5rem 0;
        height: 200px;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }

}