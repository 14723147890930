.def-button{
         background-color: #1a1a1a;
    border-color:#1a1a1a ;
      width: 150px;
      font-weight: bold;
    margin-right: 10px;
}
.def-button:hover{
   
  background-color: black;
    border-color:black;
}


@media screen and (max-width:800px) {
  
.def-button{
         background-color: black;
    border-color:black ;
          font-weight: bold;

      width: 83px;
    margin-right: 10px;
}
}
