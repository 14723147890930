.infoCard{
  margin: auto;
  margin-top: 10px;
  width: 300px;
}
.resImg{
  height: 500px;
  
}
.filterPanel{
  width:23%;

}
.list{
  color: black;
  background-color: white;
  cursor: pointer;
}

.list:hover{
  color: white;
  background-color: rgb(73, 73, 73);
    cursor: pointer;

}
.padder{
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 50px;
}
 .Blue{
    text-align: left;
   position: fixed; z-index: 1; width: 100%
  }
.cont{
  margin-top: 20px;
   padding-left: 100px; 
   padding-right: 100px;
}

.checkB{
  display: grid;
}
@media screen and (max-width:800px) {
  .padder{
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 50px;
}
  .infoCard{
  margin: auto;
  margin-top: 10px;
  width: auto;
}
  .cont{
  margin-top: 20px;
   padding-left: 20px; 
   padding-right: 20px;
}
  .resImg{
  height: 250px;
  width: 100%;
  
}
  .column {
    flex: 100% !important;
  }
  .Blue{
    text-align: center;
    position: initial;
  }
  .checkB{
   display: grid;
 justify-content: center;
}
.filterPanel{
  width:100%;
  
}

}
