.mod {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000000;
}

.mod-main {
  position:fixed;
  background: white;
  width: 60%;
  border-radius: 12px;
  height: 80%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
    max-height: 70%;
  overflow: scroll;
  padding: 10px;

}
.im{
  height: 500px;
  width: 100%;
}
.toprightCont{
  background-color: white;
  border-radius: 20px;
     top: 0;
    right: 20px;
        z-index: 200;

}
.borderGray300{
  
  border:1px solid rgb(206, 205, 205); 
width: 130px;
height: 40px;
}
.topright {
 position:absolute;
   top:0;
   right:0;
   margin: 10px;
    /* font-size: 30px; */
    z-index: 200;
 
}
.mod-container{
    display: flex;
    flex:1

}
.mod-content{
    flex:1;
    
}
.mod-title{
    font-weight: 600;
    font-size: 24px;
    color: black;
}
.mod-subtitle{
      font-weight: 500;
    font-size: 20px;
    color:black;
    margin-left: 10px;
}
.mod-text{
    font-size: 16px;
        margin-left: 10px;
        color:black;
        font-weight: 300;
        padding-right: 5px;

}
.display-block {
  display: block;
}
.minuContainer{
    border-right:1px solid rgb(206, 205, 205); 

}
.plusContainer{
    border-left:1px solid rgb(206, 205, 205); 

}
.minuContainer:hover{
  background-color: black;
  color:white
}
.minusButton{
  top: 30%;
    position: relative;
}
.display-none {
  display: none;
}
 .modImg{
   max-height: 500px;
   width: 100%;
 }
  /* smartphones, iPhone, portrait 480x320 phones */ 
 @media screen and (max-width: 600px) {
  .im{
  height: fit-content;
  width: 100%;
}

.mod-main {
  position:fixed;
  background: white;
  width: 80%;
  border-radius: 12px;
  height: auto;
  top:50%;
  left:50%;
  max-height: 80%;
  transform: translate(-50%,-50%);
  overflow-y: scroll;
  padding: 10px;
}
}
@media screen and (max-width: 1000px) {
  .im{
  height: fit-content;
  width: 100%;
}

.mod-main {
  position:fixed;
  background: white;
  width: 80%;
  border-radius: 12px;
  height: auto;
  top:50%;
  left:50%;
  max-height: 80%;
  transform: translate(-50%,-50%);
  overflow-y: scroll;
}
}