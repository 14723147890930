.slider-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.vertical-images-container {
  width: 20%;
}

.images-slider-container {
  width: 80%;
  display: flex;
}
.vertical-images-box {
    margin-top: 10px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.vertical-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
/* .vertical-images:hover{
border: 2px solid #1F5E80
} */
.vertical-active {
  border: 2px solid #1f5e80;
}
.caro-left {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 400px;
}
.caro-image{
    border-radius: 9px;
}
.caro-right {
   flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
.caro-center {
  flex: 8;
}

@media screen and (max-width: 800px) {
  .slider-container {
    width: 100%;
    display: grid;
  }
  .images-slider-container {
    order: 1;
    width: 100%;
  }
  .vertical-images-container {
    order: 2;

    width: 100%;
    display: flex;
  }
  .vertical-images-box {
    margin-left: 10px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
}
