.register-container{
    width:50%; margin: auto; margin-top: 100px
}


@media screen and (max-width:800px) {
  
.register-container{
    width:90%; margin: auto; margin-top: 100px
}
}

