.slider-layout{
    display: flex;
    width: 80%;
    margin: auto
}
.product-left-container{
    width: 70%;
}
.product-right-container{
    width: 30%;
    display: flex;
    flex-direction: column;
}
.product-right-container>h1{
    font-weight: 300;
    letter-spacing: 0.35px;
    font-size: 26px;
}
.product-price-container{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}
.product-right-price{
    font-size: 28px;
    font-weight: 700;
}
.panel-details-text{
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: black;
}
.panel-desc{
        font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

@media screen and (max-width: 1200px) {
 .slider-layout{
    display: flex;
    width: 95%;
    margin: auto
}
 
 }

@media screen and (max-width: 800px) {
    .slider-layout{
    display: grid;
    width: 90%;
    margin: auto
}
.product-left-container{
    width: 100%;
}
.product-right-container{
    width: 100%;
    margin-top: 20px;
}
 
 }