.orderContainer{
   
}
.imgContainer{
    flex:2;

}
.imag{
    width:240px;
    height:200px;
    margin: auto;
}
.detailsContainer{
    flex:2;
    display: grid;
}
.statusContainer{
    flex:2;
    display: block;

}
.salesContainer{
    flex:2;

}
@media screen and (max-width:800px) {
/* .orderContainer{
    display: block;
    
} */
.imgContainer{
    text-align: center;
    margin-bottom: 10px;
}
.imag{
     width:80%;
    height:250px;
    margin:auto
}
}