
.responsiveGrid{
  display:flex;
  justify-content:flex-start;
  flex-wrap:wrap;
  margin: auto;
  margin-top: 20px;
 /* max-width:1000px; */
  width:100%;
  

}
.cardWidth{
  width: 240px; margin: 10px
}

 @media screen and (max-width: 600px) {
  .cardWidth{
   max-width: 60%;
   margin: auto;
 }
 .responsiveGrid{
   display:flex;
   justify-content:center;
   flex-wrap:wrap;
   margin: auto;
   margin-top: 30px;
   width:100%;
  

 }
 }
@media (min-width:320px)  {
  
   .responsiveGrid{
  display:flex;
  justify-content:flex-start;
  flex-wrap:wrap;
  margin: auto;
  margin-top: 30px;
 /* max-width:1000px; */
  width:100%;
  

}
  /* smartphones, iPhone, portrait 480x320 phones */ 
   .cardWidth{
  /* max-width: 40%; */
  margin: auto;
  margin-top: 20px;
}
}
@media (min-width:481px)  { 
  
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .responsiveGrid{
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  margin: auto;
  margin-top: 30px;
 /* max-width:1000px; */
  width:100%;
  

}
  .cardWidth{
   /* max-width: 40%; */

  margin: auto;
  margin-top: 20px;
}
}
@media (min-width:641px)  { 
  .responsiveGrid{
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  margin: auto;
  margin-top: 30px;
 /* max-width:1000px; */
  width:100%;
  

}
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
  .cardWidth{
  min-width: 40%;
  margin: auto;
  margin-top: 20px;
}
}
@media (min-width:961px)  { 
  
  /* tablet, landscape iPad, lo-res laptops ands desktops */

.responsiveGrid{
  display:flex;
  justify-content:flex-start;
  flex-wrap:wrap;
  margin: auto;
 /* max-width:1000px; */
  width:100%;
  

}
.cardWidth{
  min-width: 240px; margin: 10px
}
}