.hero {
  /* Photo by mnm.all on Unsplash */
  background: url('https://images.unsplash.com/photo-1518176258769-f227c798150e') center;
  background-size: cover;
  padding: 4rem 2rem;
  /* grid styles */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
}

.hero > * {
  color: white;
}

.hero > h1 {
  font-size: 4rem;
  padding-bottom: 1rem;
}

.hero > article > p {
  font-size: 1.5rem;
  font-weight: 200;
}

.hero > article > a {
  padding: 1rem;
  margin-top: .75rem;
}

/* breweries styles */
.breweries {
  padding: 2rem;
}

.breweries > ul {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  grid-gap: 1rem;
}

.breweries > ul > li {
  border: 1px solid #E2E2E2;
  border-radius: .5rem;
  /* max-width: 360px; */
}

.breweries > ul > li > figure {
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  position: relative;
}

.breweries > ul > li > figure > img {
  width: 100%;
}

.breweries > ul > li > figure > figcaption {
  position: absolute;
  bottom: 0;
  background-color: rgba(0,0,0,.7);
  width: 100%;
}

.breweries > ul > li > figure > figcaption > h3 {
  color: white;
  padding: .75rem;
  font-size: 1.25rem;
}

.breweries > ul > li > p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem .75rem;
  color: #666666;
}

.breweries > ul > li > a {
  padding: .5rem 1rem;
  margin: .5rem;
}

/* footer */
footer {
  background-color: #333;
  padding: .75rem;
  color: white;
  text-align: center;
  font-size: .75rem;
}
.cardy {
  background-color: dodgerblue;
  color: white;
  padding: 1rem;
  height: 4rem;
}

.cardsy {
  max-width: 1200px;
  display: grid;
  grid-gap: 1rem;
}

/* Screen larger than 600px? 2 column */
@media (min-width: 630px) {
  .cardsy { grid-template-columns: repeat(2, 1fr);margin: auto; }
}
@media (max-width: 620px) {
  .cardsy { grid-template-columns: repeat(1, 1fr);margin: auto; }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
  .cardsy { grid-template-columns: repeat(3, 1fr);margin: auto; }
}