.cartPaddingRight{

padding-right:30px
}
.priceContainer{
      display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.lay {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.add{
  display: flex;
}
.layCont{
   position:fixed;
  width: 60%;
  border-radius: 12px;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
    max-height: 70%;

}
@media screen and (max-width:800px) {
.cartPaddingRight{

padding-right:0px
}
.add{
  display: grid;
}
}